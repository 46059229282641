import React from "react"

const Hero = ({ text, button, info }) => {
  return (
    <>
      <header className="masthead">
        <div className="container">
          <div className="intro-text">
            <div className="intro-heading text-uppercase">{text}</div>
            <h5 style={{textAlign: "center"}}>
              <span className="info">{info}</span>
            </h5>
            {button}
          </div>
        </div>
      </header>
    </>
  )
}

export default Hero
