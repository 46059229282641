import React from "react"

const Footer = () => {
  return (
    <>
<footer className="container py-5">
  <div className="row">
    <div className="col-12 col-md">
      <img  src='/logo.png' width='100px' alt="logo"/>
      <small className="d-block mb-3 text-muted">&copy; 2009-2022</small>
    </div>
    <div className="col-6 col-md">
      <h5>About</h5>
      <ul className="list-unstyled text-small">
        <li><a className="text-muted" href="/">Team</a></li>
        <li><a className="text-muted" href="/">Locations</a></li>
        <li><a className="text-muted" href="/">Privacy</a></li>
        <li><a className="text-muted" href="/">Terms</a></li>
      </ul>
    </div>
    <div className="col-6 col-md">
      <h5>Features</h5>
      <ul className="list-unstyled text-small">
        {/* <li><a className="text-muted" href="/">Testimonials</a></li> */}
        <li><a className="text-muted" href="/">Career</a></li>
        <li><a className="text-muted" href="/">Agile</a></li>
        <li><a className="text-muted" href="/">Business Improvement</a></li>
        <li><a className="text-muted" href="/">Services</a></li>
      </ul>
    </div>
    <div className="col-6 col-md">
      <h5>Career</h5>
      <ul className="list-unstyled text-small">
        <li><a className="text-muted" href="/career/jobs">Jobs</a></li>
        <li><a className="text-muted" href="/">Meetup</a></li>
        <li><a className="text-muted" href="/">Technology</a></li>
      </ul>
    </div>
    <div className="col-6 col-md">
      <h5>Resources</h5>
      <ul className="list-unstyled text-small">
        <li><a className="text-muted" href="/">Business</a></li>
        <li><a className="text-muted" href="/">Education</a></li>
        <li><a className="text-muted" href="/">Government</a></li>
        <li><a className="text-muted" href="/">Gaming</a></li>
      </ul>
    </div>
  </div>
</footer>
    </>
  )
}

export default Footer
